import BaseCrudService from "./baseCrud.service";
import ApiService from "./api.service";

export default class PartnerService extends BaseCrudService {

	constructor() {
		super('Partner');
	}

	save(callback, partner) {
		this.postRequest('Save', partner).then(callback);
	}

	listByInstitutionId(callback, institutionId) {
		this.getRequest("ListByInstitutionId", {institutionId: institutionId}).then(callback);
    }
    
    delete(callback, id){
        this.getRequest("Delete", {id: id}).then(callback);
    }
}