import File from "@/scripts/models/file.model";

export default class TestimonyInstitution {

	constructor() {
		this.id = 0;
		this.institutionId = 0;
		this.file = new File();
		this.name = '';
		this.position = '';
		this.text = '';
	}
}