<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ project.type }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <div class="head mt-0">
              <h4>Missão</h4>
            </div>

            <!-- content-->
            <div class="wrapper">
              <label class="d-flex text-color mb-2">Escreva abaixo a missão da instituição</label>
              <vue-editor
                v-model="institution.mission"
                :editor-toolbar="customToolbar"
                @blur="saveInstitution"
              />

              <div class="section image-grid mt-5">
                <!-- image counter -->
                <div class="pill gray right">{{imageInstitutionList.length}} fotos</div>

                <!-- section head -->
                <div class="head">
                  <h4>Galeria de Fotos</h4>
                </div>

                <div style="position:relative">
                  <div
                    class="btn-upload fal fa-cloud-upload"
                    v-if="imageInstitutionList.length < 10"
                  >
                    <input
                      type="file"
                      id="upload-activity"
                      accept="image/*"
                      @change="fileChanged"
                      ref="upload_activity"
                    />
                  </div>
                  <vue-easy-lightbox
                    :visible="galleryLightboxVisible"
                    :imgs="getGalleryLightboxImgs()"
                    :index="galleryLightboxIndex"
                    @hide="galleryLightboxVisible = false"
                  ></vue-easy-lightbox>
                  <div class="wrapper">
                    <!-- Project image -->
                    <div
                      class="item"
                      v-for="(image, index) in imageInstitutionList"
                      :key="image.id"
                    >
                      <div class="tools">
                        <a class="fal fa-search-plus" @click="openGalleryLightbox(index)"></a>
                        <a
                          class="fal fa-trash"
                          @click="openConfirmDeleteImageInstitutionModal(image.id)"
                        ></a>
                      </div>
                      <FileImageInstitution
                        :fileId="image.file.id"
                        :fileInstitution="image"
                        :alt="''"
                      />
                    </div>
                  </div>
                </div>

                <hr class="md" />
                <!-- section head -->
                <v-layout display-flex mb-4 justify-space-between>
                  <div class="head my-0 text-xs-center">
                    <h4>Depoimentos</h4>
                  </div>
                  <TestimonyInstitution
                    v-model="testimonyInstitutionList"
                    :institutionId="$route.query.institutionId"
                    btn="btn-primary"
                  />
                </v-layout>
                <div class="section">
                  <div v-for="testimony in testimonyInstitutionList" :key="testimony.id">
                    <v-layout w-100>
                      <v-avatar :size="114" color="lighten-4 mr-4">
                        <TestimonyInstitutionImage :testimony="testimony" :alt="''" />
                      </v-avatar>
                      <div class="name-profile testimony">
                        <h3>{{testimony.name}}</h3>
                        <span>{{testimony.position}}</span>
                        <p>{{testimony.text}}</p>
                      </div>
                    </v-layout>
                    <hr class="md mt-5" />
                  </div>
                </div>

                <div class="section">
                  <v-layout>
                    <div class="head w-100">
                      <h4>Parceiros</h4>
                    </div>
                    <div class="mt-3 pt-3">
                      <v-btn
                        flat
                        round
                        color="white"
                        class="btn btn-primary px-4"
                        large
                        @click="newPartner"
                      >
                        <i class="mr-2 fas fa-plus"></i>Novo Parceiro
                      </v-btn>
                    </div>
                  </v-layout>

                  <v-layout w-100 v-for="(partner, index) in partnerList" :key="partner.id">
                    <v-flex xs5 pr-2>
                      <InputText
                        ref="partnerNameInput"
                        v-model="partner.name"
                        type="text"
                        class="mb-0"
                        place-holder="Nome do Parceiro"
                        textLabel="Nome do Parceiro"
                        invalidInputMessage="Preencha o nome do parceiro"
                        @validationStatusChanged="validateStep"
                        @blur="savePartner(partner)"
                      />
                    </v-flex>
                    <InputFile
                      ref="partnerImageInput"
                      v-model="partnerList[index]"
                      textLabel="Logo"
                      invalidInputMessage="Selecione a imagem do parceiro"
                      helpLabel="Imagens sem fundo ou c/ fundo branco."
                      @validationStatusChanged="validateStep"
                      @input="savePartner(partner)"
                    />
                    <v-flex xs2 tools pt-4>
                      <v-layout row align-center justify-space-between fill-height pb-3>
                        <v-checkbox
                          v-model="partner.show"
                          class="no-message mt-0 align-center justify-space-between"
                          color="#02ACB4"
                          label="Exibir"
                          @change="savePartner(partner)"
                        ></v-checkbox>
                        <button href class="text-gray-dark" @click="removePartner(partner)">
                          <Icon :name="'fal fa-trash'" :size="16" />
                        </button>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
      <AucAlert
        ref="confirmationComponent"
        title="Você tem certeza que deseja excluir esse arquivo?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        @confirm="deleteImageInstitution(selectedImageInstitutionId)"
      />
      <AucAlert
        ref="confirmationRemovePartner"
        title="Você tem certeza que deseja excluir esse parceiro?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        @confirm="deletePartner(selectedPartner)"
      />
    </v-layout>
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click.prevent="backList()"
      >Concluir</v-btn>
    </div>
  </div>
</template>

<script type="plain/text">
import FileImageInstitution from "@/components/FileImageInstitution.vue";
import AucAlert from "@/components/AucAlert.vue";
import FooterStep from "@/components/FooterStep.vue";
import TestimonyInstitution from "@/components/TestimonyInstitution.vue";
import TestimonyInstitutionImage from "@/components/TestimonyInstitutionImage.vue";
import HelpBox from "@/components/HelpBox.vue";
import Institution from "@/scripts/models/institution.model.js";
import Partner from "@/scripts/models/partner.model.js";
import ImageInstitution from "@/scripts/models/imageInstitution.model.js";
import InstitutionService from "@/scripts/services/institution.service.js";
import ImageInstitutionService from "@/scripts/services/imageInstitution.service.js";
import TestimonyInstitutionService from "@/scripts/services/testimonyInstitution.service.js";
import PartnerService from "@/scripts/services/partner.service.js";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { VueEditor } from "vue2-editor";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { mapGetters } from "vuex";
import store from "@/store.js";
export default {
  components: {
    HelpBox,
    FooterStep,
    TestimonyInstitution,
    TestimonyInstitutionImage,
    VueEditor,
    FileImageInstitution,
    AucAlert
  },
  props: ["institutionId", "pageOptions"],
  computed: {
    ...mapGetters(["hasPermission"])
  },
  data: () => ({
    helpBoxText: HelpBoxText.About,
    project: {
      type: "Sobre a Instituição"
    },
    contentText: "Campo editor", //Documentação Segue: https://www.vue2editor.com/examples/#custom-image-handler
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    institution: new Institution(),
    institutionService: new InstitutionService(),
    imageInstitutionService: new ImageInstitutionService(),
    testimonyInstitutionService: new TestimonyInstitutionService(),
    imageInstitution: new ImageInstitution(),
    partnerService: new PartnerService(),
    partnerList: [new Partner()],
    testimonyInstitutionList: [],
    imageInstitutionList: [],
    selectedImageInstitutionId: null,
    selectedPartner: null,
    galleryLightboxVisible: false,
    galleryLightboxIndex: 0,
    partnerLightboxVisible: false,
    partnerLightboxIndex: 0,
    breadcrumbLevels: [
      { text: "Início" },
      { text: "Instituições" },
      { text: "Sobre" }
    ]
  }),
  beforeRouteEnter: (to, from, next) => {
    let hasPermission = false;
    if (to.query.institutionId && to.query.institutionId != 0) {
      if (
        store.getters.hasPermission([Permissions.UpdateInstitution]) ||
        store.getters.hasPermission([Permissions.CRUDInstitution])
      ) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    } else {
      if (store.getters.hasPermission([Permissions.CRUDInstitution])) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    if (hasPermission) next();
    else {
      store.commit(
        "SET_SNACKBAR_MESSAGE",
        "Você não tem permissão para acessar essa página."
      );
      next(false);
    }
  },
  methods: {
    backList() {
      if (!this.hasPermission([Permissions.CRUDInstitution])) {
        if (
          this.institution.mission == "" ||
          this.institution.mission == null
        ) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "Preencha o campo missão");
          return false;
        }
      }
      this.saveInstitution();
      this.$router.push({
        name: Routes.app.User,
        query: { entityId: this.institution.entity.id }
      });
    },
    openGalleryLightbox(index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.imageInstitutionList == null) return [];
      for (let i = 0; i < this.imageInstitutionList.length; i++) {
        if (
          this.imageInstitutionList[i] != null &&
          this.imageInstitutionList[i].file != null
        )
          imgs.push(this.imageInstitutionList[i].file.path);
      }
      return imgs;
    },
    saveInstitution() {
      this.institutionService.save(null, this.institution);
    },
    saveCallback(data) {
      this.imageInstitutionList = data;
    },
    savePartnerCallback(data) {
      this.listAllPartnerByInstitutionIdCallback(data);
    },
    listAllPartnerByInstitutionIdCallback(data) {
      if (data != "") {
        this.partnerList = data;
      } else {
        var partner = new Partner();
        partner.institutionId = this.$route.query.institutionId;
        this.partnerList = [partner];
      }
    },
    findByIdCallback(data) {
      this.institution = data;
    },
    listByInstitutionIdCallback(data) {
      this.imageInstitutionList = data;
    },
    openConfirmDeleteImageInstitutionModal(id) {
      this.selectedImageInstitutionId = id;
      this.$refs.confirmationComponent.open();
    },
    deleteImageInstitution(imageInstitutionId) {
      this.imageInstitutionService.delete(
        this.deleteImageInstitutionCallback,
        imageInstitutionId
      );
    },
    deleteImageInstitutionCallback() {
      this.imageInstitutionService.listByInstitutionId(
        this.listByInstitutionIdCallback,
        this.$route.query.institutionId
      );
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();
      this.imageInstitution.file.name = file.name;
      this.imageInstitution.institutionId = this.$route.query.institutionId;
      reader.onload = function() {
        this.imageInstitution.file.path = reader.result;
        this.imageInstitutionService.save(
          this.saveCallback,
          this.imageInstitution
        );
      }.bind(this);
      reader.readAsDataURL(file);
    },
    newPartner() {
      var partner = new Partner();
      partner.institutionId = this.$route.query.institutionId;
      this.partnerList.push(partner);
    },
    removePartner(partner) {
      this.selectedPartner = partner;
      this.$refs.confirmationRemovePartner.open();
    },
    deletePartner(partner) {
      this.partnerService.delete(this.deletePartnerCallback, partner.id);
    },
    deletePartnerCallback(data) {
      this.partnerService.listByInstitutionId(
        this.listAllPartnerByInstitutionIdCallback,
        this.$route.query.institutionId
      );
    },
    savePartner(partner) {
      if (partner.name != "" && partner.file.path != "") {
        this.partnerService.save(this.savePartnerCallback, partner);
      }
    },
    listByInstitutionIdTestimonyCallback(data) {
      this.testimonyInstitutionList = data;
    },
    validateStep() {}
  },
  created() {
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.changeClass("footer");
    this.institutionService.find(
      this.findByIdCallback,
      this.$route.query.institutionId
    );
    this.imageInstitutionService.listByInstitutionId(
      this.listByInstitutionIdCallback,
      this.$route.query.institutionId
    );
    this.partnerService.listByInstitutionId(
      this.listAllPartnerByInstitutionIdCallback,
      this.$route.query.institutionId
    );
    this.testimonyInstitutionService.listByInstitutionId(
      this.listByInstitutionIdTestimonyCallback,
      this.$route.query.institutionId
    );
  }
};
</script>