import BaseCrudService from "./baseCrud.service";
import ApiService from "./api.service";

export default class ImageInstitutionService extends BaseCrudService {

	constructor() {
		super('ImageInstitution');
	}

	save(callback, imageInstitution) {
		this.postRequest('Save', imageInstitution).then(callback);
	}

	delete(callback, id) {
		this.getRequest("Delete", {id: id}).then(callback);
	}

	listByInstitutionId(callback, institutionId) {
		this.getRequest("ListByInstitutionId", {institutionId: institutionId}).then(callback);
	}
}