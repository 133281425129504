<template>
  <v-layout row justify-end>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <button
          v-on="on"
          class="btn-primary v-btn--largee ma-0 v-btn--large v-btn--round white--text v-btn"
        >
          <i class="mr-3 fal fa-plus"></i>
          Gerenciar depoimentos
        </button>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Depoimentos</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex md6 xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-5 mb-2"
                large
                @click="create"
              >
                <i class="far fa-plus mr-2"></i>Novo depoimento
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal flex"
                  :class="{ 'active': selectedTestimonyId === testimony.id }"
                  @click="selectTestimony(testimony.id)"
                  v-for="testimony in testimonyList"
                  :key="testimony.id"
                >
                  <div class="mr-3">
                    <TestimonyInstitutionImage :testimony="testimony" :alt="''" />
                  </div>
                  <div class="flex-center">
                    <div class="name-testimony">{{ testimony.name }}</div>
                    <span>{{ testimony.position }}</span>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex class="pt-3 box-heart-father" md6 xs12 :class="{active: showForm}">
              <div class="list-scroll">
                <v-flex px-3>
                  <v-flex class="upload-box-profile-father testimony" shrink>
                    <div
                      class="upload-image"
                      id="preview"
                      ref="preview"
                      :style="'backgroundImage:' + getImage()"
                    >
                      <label for="upload-institution">
                        <i class="fas fa-camera-alt"></i>
                      </label>
                      <label
                        for="upload-institution"
                        class="do-upload"
                        v-show="testimonyInstitution.file.path == ''"
                      >
                        <i class="fal fa-cloud-upload"></i>Foto
                      </label>
                      <input
                        type="file"
                        id="upload-institution"
                        accept="image/*"
                        @change="fileChanged"
                        ref="upload_institution"
                      />
                    </div>
                  </v-flex>
                  <InputText
                    ref="nameInput"
                    type="text"
                    textLabel="Nome"
                    v-model="testimonyInstitution.name"
                    labelCharLimit="30"
                    invalidInputMessage="Preencha o nome"
                  />
                  <InputText
                    ref="positionInput"
                    type="text"
                    textLabel="Cargo"
                    v-model="testimonyInstitution.position"
                    labelCharLimit="50"
                    invalidInputMessage="Preencha o cargo"
                  />
                  <div class="t-area-heart-step">
                    <label>Descreva o depoimento</label>
                    <InputTextArea
                      ref="textInput"
                      v-model="testimonyInstitution.text"
                      labelAreaCharLimit="300"
                      invalidInputMessage="Preencha o depoimento"
                    ></InputTextArea>
                  </div>
                </v-flex>
              </div>
              <v-flex class="align-center justify-space-between footer-step-modal">
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="openConfirmDeleteTestimonyModal(testimonyInstitution.id)"
                  :disabled="institutionId == 0"
                >Excluir</v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="isValid"
                  :disabled="institutionId == 0"
                >Salvar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse depoimento?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedTestimonyId)"
            :disabled="institutionId == 0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import TestimonyInstitutionImage from "@/components/TestimonyInstitutionImage.vue";
import TestimonyInstitution from "@/scripts/models/testimonyInstitution.model";
import TestimonyInstitutionService from "@/scripts/services/testimonyInstitution.service";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  components: {
    AucAlert,
    TestimonyInstitutionImage
  },
  props: {
    value: Array,
    institutionId: 0
  },
  data() {
    return {
      testimonyInstitutionService: new TestimonyInstitutionService(),
      testimonyInstitution: new TestimonyInstitution(),
      testimonyList: [],
      selectedTestimonyId: null,
      dialog: false,
      showForm: false
    };
  },
  created() {
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
  },
  watch: {
    dialog(v) {
      if (v) {
        this.create();
      }
    }
  },
  methods: {
    openConfirmDeleteTestimonyModal(id) {
      if (id != 0) {
        this.selectedTestimonyId = id;
        this.$refs.confirmationComponent.open();
      }
    },
    listByInstitutionId() {
      this.testimonyInstitutionService.listByInstitutionId(
        this.listByInstitutionIdCallback,
        this.institutionId
      );
    },
    listByInstitutionIdCallback(data) {
      this.testimonyList = data;
      this.$emit("input", data);
    },
    selectTestimony(testimonyId) {
      let testimony = this.testimonyList.find(
        testimony => testimony.id == testimonyId
      );
      this.selectedTestimonyId = testimonyId;
      this.copy(testimony);
    },
    copy(testimony) {
      this.testimonyInstitution = new TestimonyInstitution();
      this.testimonyInstitution.id = testimony.id;
      this.testimonyInstitution.institutionId = testimony.institutionId;
      this.testimonyInstitution.name = testimony.name;
      this.testimonyInstitution.position = testimony.position;
      this.testimonyInstitution.text = testimony.text;
      this.testimonyInstitution.imageContent = testimony.imageContent;
      this.testimonyInstitution.file.id = testimony.file.id;
      this.testimonyInstitution.file.userId = testimony.file.userId;
      this.testimonyInstitution.file.name = testimony.file.name;
      this.testimonyInstitution.file.path = testimony.file.path;
      this.testimonyInstitution.file.size = testimony.file.size;
      this.testimonyInstitution.file.extension = testimony.file.extension;
      this.testimonyInstitution.file.origin = testimony.file.origin;
      this.testimonyInstitution.file.publicId = testimony.file.publicId;
    },
    create() {
      this.testimonyList = this.value;
      this.selectedTestimonyId = 0;
      this.testimonyInstitution = new TestimonyInstitution();
      this.$refs.preview.style.backgroundImage = "";
    },
    getImage() {
      if (
        this.testimonyInstitution == null ||
        this.testimonyInstitution.id == 0
      )
        return "";
      return "url(" + this.testimonyInstitution.imageContent + ")";
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();
      this.testimonyInstitution.file.name = file.name;
      reader.onload = function() {
        this.testimonyInstitution.file.path = reader.result;
        this.testimonyInstitution.imageContent = reader.result;
        this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      this.testimonyInstitution.institutionId = this.institutionId;
      this.testimonyInstitutionService.save(
        this.saveCallback,
        this.testimonyInstitution
      );
    },
    saveCallback(data) {
      this.listByInstitutionId();
    },
    remove(selectedTestimonyId) {
      debugger;
      this.testimonyInstitutionService.delete(
        this.removeCallback,
        selectedTestimonyId
      );
    },
    removeCallback() {
      this.listByInstitutionId();
      this.create();
    },
    validate(performMethods) {
      if (
        this.testimonyInstitution.file == null ||
        this.testimonyInstitution.file.path == ""
      ) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Escolha uma imagem para o depoimento"
        );
        return false;
      }
      return this.validateComponents(performMethods);
    },
    validationSuccess() {
      this.save();
    }
  }
};
</script>