import File from "@/scripts/models/file.model";
export default class Partner {

    constructor() {
        this.id = 0;
        this.institutionId = 0;
        this.order = 0;
        this.id = 0;
        this.name = '';
        this.show = false;
        this.file = new File();
    }
}