import BaseCrudService from "./baseCrud.service";

export default class TestimonyInstitutionService extends BaseCrudService{
    constructor(){
        super("TestimonyInstitution");
    }

    save(callback, testimonyInstitution){
        this.postRequest("Save", testimonyInstitution).then(callback);
    }

    delete(callback, id){
        this.getRequest("Delete", {id: id}).then(callback);
    }

    listByInstitutionId(callback, institutionId) {
		this.getRequest("ListByInstitutionId", {institutionId : institutionId}).then(callback);
	}
}